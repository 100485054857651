
<button
  :form="form"
  :aria-label="label"
  :class="['btn', { 'btn--icon': icon }, buttonVariantClass]"
  :disabled="disabled"
  @click="clickHandler"
>
  <i v-if="icon" :class="['icon', 'fa', 'fa-fw', `fa-${icon}`]" />
  <span v-if="!hideLabel">{{ label }}</span>
</button>
