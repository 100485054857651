
<div class="sidebar">
  <div class="flex__column">
    <h1 class="sidebar__header title-1">{{ $t('general.appName') }}</h1>
    <router-link :to="{ name: 'Home' }" class="btn btn--ter btn--sidebar sidebar__item" :class="{ active: $route.name === 'Home' }">
      <h1>{{ $t('general.frontPage') }}</h1>
    </router-link>

    <hr class="divider" />

    <h2 class="btn btn--ter sidebar__item sidebar__item--organizations" @click="isCollapsed = !isCollapsed">
      {{ $t('general.orgs') }}
      <i class="fa" :class="isCollapsed ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
    </h2>
    <div v-if="isCollapsed">
      <button
        v-for="org in organizations"
        :key="org.id"
        class="btn btn--ter sidebar__item sidebar__item--org"
        :class="{ active: activeOrganization && activeOrganization.id === org.id }"
        @click="handleActiveOrganization(org)"
      >
        {{ org.name }}
      </button>
    </div>

    <hr class="divider" />

    <div v-if="!user" class="sidebar__header">{{ $t('general.signIn') }}</div>
    <template v-if="user">
      <ul v-if="activeOrganization" class="sidebar__group">
        <li v-for="org in tree" :key="org.id" class="margin-top-1">
          <template v-if="org.id === activeOrganization.id">
            <router-link
              :class="{ active: org.slug === $route.params.slug }"
              :to="{ name: 'ItemHome', params: { slug: org.slug } }"
              class="btn btn--ter sidebar__item"
              @click.native="handleNavigation"
            >
              <h2>{{ org.name }}</h2>
            </router-link>
            <ul>
              <li v-for="dept in org.children" :key="dept.id" class="margin-top-1">
                <router-link
                  :class="{ active: dept.slug === $route.params.slug }"
                  :to="{ name: 'ItemHome', params: { slug: dept.slug } }"
                  class="btn btn--ter sidebar__item"
                  @click.native="handleNavigation"
                >
                  <h3>{{ dept.name }}</h3>
                </router-link>
                <ul>
                  <li v-for="prod in dept.children" :key="prod.id" class="card--prod">
                    <router-link
                      :class="{ active: prod.slug === $route.params.slug }"
                      :to="{ name: 'ItemHome', params: { slug: prod.slug } }"
                      class="btn btn--ter sidebar__item sidebar__item--product"
                      @click.native="handleNavigation"
                    >
                      <h3>{{ prod.name }}</h3>
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </template>
        </li>
      </ul>
    </template>
  </div>
  <div class="flex__row--space-between">
    <div class="logo">
      <knowit-logo class="logo__img" />
    </div>
    <div class="align__self--center">v{{ appVersion }}</div>
  </div>
</div>
