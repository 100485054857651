
<validation-provider v-slot="{ errors }" :rules="rules" :name="name || label">
  <label class="form-group">
    <span
      :class="{
        'form-label': true,
        'form-label--hasPrimaryBackground': hasPrimaryBackground,
      }"
    >
      {{ label || name }}
    </span>

    <slot name="help"></slot>

    <div class="form-input__wrapper">
      <input
        v-if="inputType === 'input'"
        v-model="innerValue"
        :type="type"
        :name="name"
        :disabled="disabled"
        :readonly="readonly"
        :placeholder="placeholder"
        :class="fieldClass"
        :data-cy="dataCy"
        step="any"
      />

      <textarea
        v-if="inputType === 'textarea'"
        v-model="innerValue"
        :disabled="disabled"
        :name="name"
        :readonly="readonly"
        :placeholder="placeholder"
        :class="fieldClass"
        rows="4"
        :data-cy="dataCy"
      />

      <v-select
        v-if="inputType === 'select'"
        v-model="innerValue"
        :name="name"
        :label="selectLabel"
        :options="selectOptions"
        :clearable="false"
        :data-cy="dataCy"
      >
        <template #option="option">
          {{ option.name }}
          <span v-if="option.period && option.period.name">
            ({{ option.period.name }})
          </span>
        </template>
      </v-select>

      <button
        v-if="copyButton"
        v-tooltip="$t('tooltip.copyToClipboard')"
        class="btn btn--sec"
        @click="copyFieldText"
      >
        <i class="form-label__copy-button far fa-clone" />
      </button>
    </div>

    <span v-if="errors[0]" class="form-field--error">{{ errors[0] }}</span>
  </label>
</validation-provider>
